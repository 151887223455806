import { discord, twitter, github } from '../svg';
export const main = [
    {
        text: 'Docs',
        url: 'https://docs.frame.sh'
    },
    {
        text: 'Blog',
        url: 'https://blog.frame.sh'
    },
    {
        text: 'Feedback',
        url: 'https://feedback.frame.sh'
    },
    {
        text: 'Careers',
        url: 'https://framelabs.notion.site/Join-Frame-Labs-bf796117a50040159b61c092ad89870d'
    }
];
export const social = [
    {
        text: 'Discord',
        svg: discord,
        url: 'https://discord.gg/rr4Yr3JkPq'
    },
    {
        text: 'Twitter',
        svg: twitter,
        url: 'https://twitter.com/0xFrame'
    },
    {
        text: 'GitHub',
        svg: github,
        url: 'https://github.com/frame-labs'
    }
];
